.tk-loader__container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--tk--color--secondary);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: var(--tk--section--padding-left);
    transition: opacity .6s;
    pointer-events: none;
}

.tk-loader__content {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tk-loader__content .tk-logo {
    height: 4rem;
}

.tk-loader__content * {
    color: white;
}

.tk-loader-progress__container {
    height: .5rem;
    border-radius: var(--tk--default--border--radius);
    overflow: hidden;
    width: 10rem;
    max-width: 100%;
    background: rgba(0, 0, 0, 0.318);
}

.tk-loader-progress__bar {
    height: 100%;
    background: var(--tk--color--background);
}