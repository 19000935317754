    .tk-viz__container {
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
    }
    
    .tk-viz__inner-container {
        height: 100%;
        width: 100%;
        overflow: hidden;
    }
    
    .tk-viz__inner-container canvas {
        /* transform: scale(.9);
    border-radius: 4rem; */
    }
    
    body {
        min-height: 200vh;
        background: var(--tk--color--background);
    }
    /**App canvas*/
    
    #tk-app-canvas-container {
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        width: 100%;
        height: 100vh;
    }
    /**buttons*/
    
    .tk-buttons {
        display: flex;
        flex-wrap: wrap;
        gap: calc(.5 * var(--tk--block-gap));
    }
    
    .tk-button a {
        display: block;
        text-decoration: none;
        border: solid 2px var(--tk--color--secondary);
        width: max-content;
        color: white;
        font-size: 1.2rem;
        border-radius: var(--tk--default--border--radius);
        padding: .6rem 1rem;
        background: var(--tk--color--secondary);
    }
    
    .tk-button:hover a {
        border-color: black;
        background: var(--tk--color--foreground);
    }
    
    .tk-button--outlined a {
        background: transparent;
        color: var(--tk--color--secondary);
    }
    
    .tk-button--outlined:hover a {
        color: white;
        background: var(--tk--color--foreground);
    }
    
    .tk-button.tk-button--small a {
        padding: .4rem .8rem;
        font-size: 1rem;
    }
    /**Tags*/
    
    .tk-tags {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: calc(.25 * var(--tk--block-gap));
    }
    
    .tk-tag {
        color: white;
        border: solid 1px white;
        padding: .2rem .4rem;
        border-radius: var(--tk--default--border--radius);
        font-size: .8rem;
    }
    /**spacers*/
    
    .tk-spacer--block-gap {
        height: var(--tk--block-gap);
    }
    
    .tk-spacer--half-block-gap {
        height: calc(.5 * var(--tk--block-gap));
    }
    /*Sections*/
    
    #tk-section-hero {
        height: 100vh;
    }
    
    .tk-section {
        overflow: hidden;
        display: flex;
        justify-content: center;
        width: 100%;
    }
    
    .tk-section-content-wrapper {
        padding-top: var(--tk--section--padding-top);
        padding-bottom: var(--tk--section--padding-bottom);
        padding-left: var(--tk--section--padding-left);
        padding-right: var(--tk--section--padding-right);
        width: 100%;
        box-sizing: border-box;
        max-width: var(--tk--max-content-width);
    }
    
    .tk-section.tk-section-alt {
        background: var(--tk--color--background-alt);
    }
    
    #portfolio {
        position: relative;
    }
    
    #portfolio h1,
    #portfolio p {
        color: white;
    }
    /*about section*/
    
    #tk-about-viz-view__container {
        position: relative;
        /* top: calc(.5 * (.25 * var(--tk--section--padding-top) + .25 * var(--tk--section--padding-bottom)));
        height: calc(100% - (.25 * var(--tk--section--padding-top) + .25 * var(--tk--section--padding-bottom))) !important; */
    }
    /**footer*/
    
    footer.tk-section .tk-section-content-wrapper {
        padding-top: var(--tk--block-gap);
        padding-bottom: var(--tk--block-gap);
    }
    /**columns*/
    
    .tk-columns {
        display: flex;
        gap: var(--tk--block-gap)
    }
    
    .tk-column--50 {
        width: 50%;
    }
    
    .tk-column--66 {
        width: 66%;
    }
    
    .tk-column--100 {
        width: 100%;
    }
    
    .tk-column--center {
        justify-content: center;
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    /**Portfolio Swiper slides*/
    
    swiper-slide {
        height: auto;
        margin-top: 16px;
        margin-bottom: 16px;
    }
    
    .tk-swiper-nav-button {
        cursor: pointer;
        transition: opacity .6s;
    }
    
    .tk-swiper-nav__container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: var(--tk--block-gap);
        align-items: center;
    }
    
    .tk-swiper-nav-button.swiper-button-disabled {
        opacity: .4;
        pointer-events: none;
    }
    
    .tk-portfolio-slide {
        position: relative;
        height: 100%;
        border-radius: var(--tk--default--border--radius);
    }
    
    .swiper-slide-active .tk-portfolio-slide {
        transform: scale(1);
    }
    
    .tk-portfolio-slide__bg {
        border-radius: var(--tk--default--border--radius);
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: transform .6s;
    }
    
    .tk-portfolio-slide__link-wrapper {
        text-decoration: none;
    }
    
    .tk-portfolio-slide__bg-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: black;
        border-radius: var(--tk--default--border--radius);
    }
    
    .tk-portfolio-slide__bg-img {
        display: block;
        object-fit: cover;
        border-radius: var(--tk--default--border--radius);
        width: 100%;
        min-height: 16rem;
        max-height: 16rem;
    }
    
    .tk-button-portfolio a {
        color: var(--tk--color--foreground) !important;
        background: var(--tk--color--background) !important;
        border-color: var(--tk--color--background) !important;
    }
    
    .tk-portfolio-slide__image-overlay {
        flex-grow: 1;
        width: 100%;
    }
    
    .tk-portfolio-slide__content-wrapper {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .tk-portfolio-slide__content {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    
    .tk-portfolio-slide__content-title,
    .tk-portfolio-slide__content-description {
        color: white;
    }
    
    .tk-portfolio-slide__content-date {
        font-weight: normal;
        color: #a4a4a4;
    }
    
    .tk-button-portfolio a {
        color: white;
        text-decoration: none;
    }
    
    .tk-portfolio-slide__logo {
        width: 100%;
        object-fit: contain;
        object-position: center center;
        height: 3rem;
    }
    /**
    * Contact
    */
    
    .tk-social-buttons {
        display: flex;
        flex-wrap: wrap;
        gap: calc(.25 * var(--tk--block-gap))
    }