:root {
    --tk--section--padding-top: 10rem;
    --tk--section--padding-bottom: 10rem;
    --tk--section--padding-right: 2rem;
    --tk--section--padding-left: 2rem;
    --tk--block-gap: 2rem;
    --tk--default--border--radius: 1rem;
    --tk--color--secondary: rgb(235, 160, 92);
    --tk--color--tertiary: #1D244C;
    --tk--color--foreground: #0f0f0f;
    --tk--color--background: #fff1dd;
    --tk--color--background-alt: #ffe7ca;
    --tk--max-content-width: 1520px;
}

html {
    font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--tk--color--foreground);
    margin: 0;
}

p {
    color: var(--tk--color--foreground);
}

h1 {
    font-size: 4rem;
}

h2 {
    font-size: 2rem;
    color: var(--tk--color--secondary);
}

h3 {
    font-size: 1.4rem;
    color: var(--tk--color--secondary);
}

body {
    margin: 0;
    font-family: Poppins;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


/*helpers*/

.tk-background--secondary {
    background: var(--tk--color--secondary);
}

.tk-style-height--100perc {
    height: 100%;
}

.tk-style-position--relative {
    position: relative;
}