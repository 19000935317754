.tk-menu__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 998;
}

.tk-columns.tk-menu__columns {
    gap: 0px;
}

.tk-menu__column {
    box-sizing: border-box;
    padding: var(--tk--block-gap);
    justify-content: center;
    align-items: center;
    position: relative;
}

.tk-menu__column .tk-menu-column__background {
    background: var(--tk--color--background);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.tk-menu__column:nth-of-type(1) .tk-menu-column__background {
    background: var(--tk--color--secondary);
}

.tk-menu__column:nth-of-type(1) .tk-menu__column-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.tk-menu__column:nth-of-type(2) .tk-menu__column-content {
    overflow: hidden;
}

.tk-menu__column-content {
    width: 100%;
    margin-top: calc(80px - 2rem);
    z-index: 2;
    max-width: 600px;
}

ul.tk-menu {
    list-style-type: none;
    padding-left: 0px;
    margin-left: 0px;
    list-style-position: inside;
}

.tk-menu li {
    padding-left: 0px;
    margin-left: 0px;
    overflow: hidden;
}

.tk-menu li a {
    text-decoration: none;
    display: block;
    width: max-content;
}

.tk-menu li a h1:hover {
    color: var(--tk--color--background) !important;
}