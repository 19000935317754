@media (max-width: 968px) {
    html {
        font-size: 14px;
    }
}

@media (max-width: 768px) {
     :root {
        --tk--section--padding-top: 4rem;
        --tk--section--padding-bottom: 4rem;
        --tk--section--padding-right: 2rem;
        --tk--section--padding-left: 2rem;
    }
    .tk-columns {
        flex-direction: column;
    }
    .tk-columns .tk-column {
        width: 100%;
    }
    /*mobile menu*/
    .tk-menu__column--r {
        display: none !important;
    }
    .tk-menu li {
        display: flex;
        justify-content: center;
        text-align: center;
    }
    /*About*/
    #about #tk-about-viz-view__wrapper,
    #contact #tk-contact-viz-view__wrapper {
        transform: translateX(0px) !important;
        position: relative !important;
        top: auto !important;
        left: auto !important;
        height: 24rem !important;
        width: calc(100% + var(--tk--section--padding-left) + var(--tk--section--padding-right)) !important;
        margin-left: calc(-1 * var(--tk--section--padding-left)) !important;
    }
    #about #tk-about-viz-view__container,
    #contact #tk-contact-viz-view__container {
        padding-left: 0% !important;
    }
    #tk-about-viz-view__container {
        top: 0px !important;
    }
}

@media (max-width: 480px) {
    h1 {
        font-size: 2.8rem;
    }
    .tk-custom-shape-divider-bottom svg,
    .tk-custom-shape-divider-top svg {
        height: 1.2rem !important;
    }
}