.tk-custom-shape-divider-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.tk-custom-shape-divider-top svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 2rem;
}

.tk-custom-shape-divider-top .shape-fill {
    fill: #FFF1DD;
}

.tk-custom-shape-divider-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.tk-custom-shape-divider-bottom svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 2rem;
}

.tk-custom-shape-divider-bottom .shape-fill {
    fill: #FFF1DD;
}