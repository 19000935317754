.tk-header__container {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity .3s;
    pointer-events: none;
    opacity: 0;
}

.tk-header__container--active {
    opacity: 1;
    pointer-events: auto;
}

.tk-header__inner-container {
    box-sizing: border-box;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: var(--tk--section--padding-left);
    padding-right: var(--tk--section--padding-right);
    width: 100%;
    max-width: var(--tk--max-content-width);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tk-header__hamburger {
    box-shadow: 2px 2px 9px 2px rgba(0, 0, 0, .2);
    border-radius: calc(.5 * var(--tk--default--border--radius));
    color: white !important;
    background: var(--tk--color--secondary);
}

.tk-logo {
    height: 2rem;
    filter: drop-shadow(3px 3px 6px #00000039);
}

.tk-logo path {
    fill: white;
    color: black;
    /* stroke: var(--tk--color--secondary);
    stroke-width: 1.5rem; */
}